import React, { useState, useEffect, useContext } from 'react';
import "./KusumYojana.css"
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';

import kusum1 from "../../assets/kusum/kusum-1.webp";
import kusum2 from "../../assets/kusum/kusum-2.webp";
import kusum3 from "../../assets/kusum/kusum-3.webp";
import kusum4 from "../../assets/kusum/kusum-4.webp";
import kusum5 from "../../assets/kusum/kusum-5.webp";

import kusum6 from "../../assets/kusum/kusum-6.webp"
import kusum7 from "../../assets/kusum/kusum-7.webp"
import kusum8 from "../../assets/kusum/kusum-8.webp"
import kusum9 from "../../assets/kusum/kusum-9.webp"

import kusum10 from "../../assets/kusum/kusum-10.svg"
import kusum11 from "../../assets/kusum/kusum-11.webp"

import kusum12 from "../../assets/kusum/kusum-12.webp"
import kusum13 from "../../assets/kusum/kusum-13.webp"
import kusum14 from "../../assets/kusum/kusum-14.webp"
import kusum15 from "../../assets/kusum/kusum-15.webp"
import kusum16 from "../../assets/kusum/kusum-16.webp"

import kusum18 from "../../assets/kusum/kusum-18.webp"

import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import { AppContext } from '../../AppContext';

const NavbarHeight = 100;

const KusumYojana = () => {
  const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm({
    defaultValues: {
      enquiry_type: '0'
    }
  });
  const { states, city, setSelectedState } = useContext(AppContext);

  const selectedState = watch('state')

  useEffect(() => {
    setSelectedState(selectedState);
  }, [selectedState, setSelectedState]);

  const Navigate = useNavigate()

  const [captchaError, setCaptchaError] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleVerificationSuccess = (token) => {
    setCaptchaToken(token);
  };

  const handleLinkClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.pageYOffset - NavbarHeight;

      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };


  const onSubmit = async (data) => {
    data.captchaToken = captchaToken;
    const formBody = new URLSearchParams(data).toString();
    try {
      const response = await fetch('https://kesrinandan.com/admin/api/quote-enquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
      });
      const responseData = await response.json();
      if (response.ok) {
        reset()
        Navigate("/thank-you")
      } else {
        if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
          setCaptchaError(responseData.msg);
        } else {
          console.error('Failed to submit the form:', responseData.error);
        }
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  const steps = [
    {
      title: "आधिकारिक वेबसाइट पर जाएँ",
      description: "PM-KUSUM योजना की आधिकारिक वेबसाइट या राज्य कार्यान्वयन एजेंसी के पोर्टल पर जाएँ।",
      bgColor: " rgb(255 243 224 / 48%)",
      textColor: "#f57c00"
    },
    {
      title: "आवेदन पत्र भरें",
      description: "ऑनलाइन आवेदन पत्र को पूरी तरह से भरें, जिसमें व्यक्तिगत और कृषि संबंधी जानकारी शामिल हो।",
      bgColor: "rgb(255 232 232 / 48%)",
      textColor: "#e53935"
    },
    {
      title: "आवश्यक दस्तावेज़ भेजें",
      description: "आवश्यक दस्तावेज़ अपलोड करें, जिसमें पहचान पत्र, संपत्ति के दस्तावेज़, और बैंक खाता जानकारी शामिल हो।",
      bgColor: "rgb(232 245 233 / 48%)",
      textColor: "#43a047"
    },
    {
      title: "आवेदन की समीक्षा",
      description: "संबंधित अधिकारी आवेदन की समीक्षा करेंगे। यदि स्वीकार कर लिया जाता है, तो आपको एक पुष्टि भेजी जाएगी।",
      bgColor: "rgb(243 229 245 / 48%)",
      textColor: "#8e24aa"
    },
    {
      title: "स्थापना और बोनस",
      description: "मंजूरी के बाद सौर पैनल या पंप स्थापित किया जाएगा, और कुसुम योजना की मानदंडों के अनुसार सब्सिडी प्रदान की जाएगी।",
      bgColor: "rgb(255 243 224 / 48%)",
      textColor: "#f57c00"
    }
  ];

  return (
    <>
      <Helmet>
        <title>PM Kisan Kusum Solar Pump Yojna | Kesrinandan </title>
        <meta
          name="description"
          content="Learn about PM किसान कुसुम योजना and how Kusum Solar Yojana empowers farmers by providing subsidies for solar energy systems. Enquire Now!"
        />

        <link rel="canonical" href="https://kesrinandan.com/kusum-yojana" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://kesrinandan.com/kusum-yojana" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="PM Kisan Kusum Solar Pump Yojna | Kesrinandan " />
        <meta property="og:description" content="Learn about PM किसान कुसुम योजना and how Kusum Solar Yojana empowers farmers by providing subsidies for solar energy systems. Enquire Now!" />
        <meta property="og:image" content="https://kesrinandan.com/static/media/kusum-banner.8f56ef02e9a8e75a56e8.webp" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="kesrinandan.com" />
        <meta property="twitter:url" content="https://kesrinandan.com/kusum-yojana" />
        <meta name="twitter:title" content="PM Kisan Kusum Solar Pump Yojna | Kesrinandan " />
        <meta name="twitter:description" content="Learn about PM किसान कुसुम योजना and how Kusum Solar Yojana empowers farmers by providing subsidies for solar energy systems. Enquire Now!" />
        <meta name="twitter:image" content="https://kesrinandan.com/static/media/kusum-banner.8f56ef02e9a8e75a56e8.webp" />

      </Helmet>


      <section className="about-banner kusum-banner">
        <div className="custom-text custom-text-about">
          <p className='custom-title'>PM-KUSUM Scheme<span className='color-dot'>.</span></p>
          <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'PPA', url: '/ppa' }]} />
        </div>
      </section>


      <section className='kusum-main-section c-container'>
        <div className="kusum-main-header">
          <h1 className='kusum-header-small-title'>PM-KUSUM Scheme</h1>
          <p className='kusum-header-big-title'>PM-KUSUM Scheme: Objectives, Benefits, Components & Steps to Register</p>
          <p className='kusum-header-desc'>PM-KUSUM (प्रधानमंत्री किसान ऊर्जा सुरक्षा एवं उत्थान महाभियान) भारत सरकार की एक परिवर्तनकारी पहल है, जिसका उद्देश्य सौर ऊर्जा के उपयोग के माध्यम से किसानों को सशक्त बनाना है। यह योजना न केवल किसानों को आर्थिक रूप से समर्थन देती है, बल्कि कृषि क्षेत्र में नवीकरणीय ऊर्जा स्रोतों को अपनाने को भी प्रोत्साहित करती है। At Kesrinandan, we will break down the primary goals of the PM-KUSUM yojana, the various advantages it offers to farmers, the key elements that constitute the program, and the step-by-step registration procedures to help farmers take full advantage of this initiative.</p>
        </div>

        <div className="ksusum-main-content">
          <aside className="sidebar">
            <div className="sidebar-content">
              <ul>
                <li><a onClick={(event) => handleLinkClick(event, 'kusum-undestanding')} className='sidebar-link'>Understanding the PM-KUSUM Yojana</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'objectives')} className='sidebar-link'>Objectives Of PM-KUSUM Scheme</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'components')} className='sidebar-link'>Components of the PM-KUSUM Yojana</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'benefits')} className='sidebar-link'>Benefits Of PM-KUSUM Yojana</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'steps-section')} className='sidebar-link'>Steps to Register for the PM-KUSUM Scheme</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'initiative')} className='sidebar-link'>How Can You Invest In PM-KUSUM Yojana</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'invest')} className='sidebar-link'>Steps To Invest in PM-KUSUM Yojana</a></li>
              </ul>
              <div className="sidebar-cta">
                <h3>Go Solar Today! 🌞</h3>
                <p>Join the clean energy movement and start saving on your energy bills. Register your solar panels now and enjoy sustainable power for years to come!</p>
                <button>Register Now</button>
              </div>
            </div>
          </aside>

          <main className="kusum-content">
            <section className='kusum-undestanding' id='kusum-undestanding'>
              <img src={kusum1} alt="Understanding PM-KUSUM" />
              <div className="understanding-content">
                <h2>Understanding the PM-KUSUM Yojana</h2>
                <p>There are a number of initiatives that make up the PM Kisan KUSUM Yojana, which aims to improve solar energy utilization in agriculture. इनमें स्वतंत्र सोलर कृषि पंपों की स्थापना, मौजूदा पंपों का सोलराइजेशन और विकेन्द्रीकृत सौर ऊर्जा संयंत्र शामिल हैं। जो किसान कुसुम योजना में भाग लेते हैं, वे पारंपरिक ऊर्जा स्रोतों पर अपनी निर्भरता को काफी हद तक कम कर सकते हैं।</p>
              </div>
            </section>

            <section id='objectives'>
              <h2>Objectives Of PM-KUSUM Scheme</h2>
              <p>Here are the key objectives of the PM-KUSUM yojana</p>
              <div className="objectives">
                <ul>
                  <li>
                    <div className="objective-img">
                      <img src={kusum2} alt="" />
                    </div>
                    <div>
                      <h3>सौर ऊर्जा को बढ़ावा देना</h3>
                      <p>पारंपरिक ऊर्जा स्रोतों पर निर्भरता कम करने के लिए कृषि में सौर ऊर्जा के उपयोग को प्रोत्साहित करना।</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img">
                      <img src={kusum3} alt="" />
                    </div>
                    <div>
                      <h3>किसानों को सशक्त बनाना</h3>
                      <p>किसानों को एक भरोसेमंद और सतत ऊर्जा स्रोत उपलब्ध कराकर उनकी बिजली लागत को कम करना।</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img">
                      <img src={kusum4} alt="" />
                    </div>
                    <div>
                      <h3>Boost Farmers' Income</h3>
                      <p>PM Kisan KUSUM Yojana allows farmers to resell any excess electricity their solar plants produce to the grid, creating a new revenue stream</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img">
                      <img src={kusum5} alt="" />
                    </div>
                    <div>
                      <h3>Minimize Carbon Footprint</h3>
                      <p>ग्रीनहाउस गैसों के उत्सर्जन को कम करके, किसान एक स्थायी पर्यावरण सुनिश्चित करने में भी मदद कर सकते हैं।</p>
                    </div>
                  </li>
                </ul>
              </div>
            </section>

            <section id='components'>
              <h2>Components of the PM-KUSUM Yojana</h2>
              <p>There are four essential elements make up the PM-KUSUM Yojana, which promotes the use of solar energy in agriculture:</p>
              <div className="components">
                <div className="component">
                  <img src={kusum6} alt="Component A" />
                  <h3>घटक A</h3>
                  <p>2 मेगावाट की अधिकतम क्षमता वाले विकेन्द्रीकृत, ग्रिड से जुड़े, भू-आधारित नवीकरणीय ऊर्जा संयंत्रों की स्थापना करना घटक A है।</p>
                </div>
                <div className="component">
                  <img src={kusum7} alt="Component B" />
                  <h3>घटक B</h3>
                  <p> सिंचाई की स्थिरता को बढ़ाने के लिए मौजूदा डीजल पंपों की जगह सौर ऊर्जा से संचालित कृषि पंप स्थापित किए जाएंगे।</p>
                </div>
                <div className="component">
                  <img src={kusum8} alt="Component C (IPS)" />
                  <h3>घटक C (IPS)</h3>
                  <p>मौजूदा ग्रिड से जुड़े कृषि पंपों का सौरकरण, जिससे किसान सिंचाई के लिए सौर ऊर्जा का उपयोग कर सकें।</p>
                </div>
                <div className="component">
                  <img src={kusum9} alt="Component C (FLS)" />
                  <h3>घटक C (FLS)</h3>
                  <p>मौजूदा कृषि पंपों का सौरकरण, जिसमें सौर ऊर्जा बुनियादी ढांचे के वित्तपोषण पर ध्यान दिया जाएगा ताकि स्थिरता और कवरेज में सुधार हो सके।</p>
                </div>
              </div>
            </section>

            <section id='benefits'>
              <h2>Benefits Of PM-KUSUM Yojana</h2>
              <p>For farmers, the PM Kisan KUSUM Yojana provides several advantages:</p>
              <div className="benefits">
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Subsidized Solar Pumps</h3>
                  </div>
                  <p>The Kusum Yojana subsidy allows farmers to get subsidies for solar pumps, bringing down the cost of solar energy.</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Energy Independence</h3>
                  </div>
                  <p>The Kusum Solar Panel Yojana's solar pumps guarantee a steady supply of electricity for irrigation, increasing crop yield.</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Cost Savings</h3>
                  </div>
                  <p>किसान सौर ऊर्जा में परिवर्तित होकर अपनी बिजली की लागत को महत्वपूर्ण रूप से कम कर सकते हैं।</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Income Generation</h3>
                  </div>
                  <p>By selling excess electricity to the grid under the PM Kisan Kusum Yojana, farmers can generate additional revenue.</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Environmental Benefits</h3>
                  </div>
                  <p>सौर ऊर्जा का उपयोग सतत कृषि पद्धतियों को बढ़ावा देने और कार्बन उत्सर्जन को कम करने में योगदान करता है।</p>
                </div>
              </div>
            </section>

            <section className="steps-section" id='steps-section'>
              <h2>Steps to Register for The PM-KUSUM Scheme</h2>
              <p>PM-KUSUM योजना के लिए साइन अप करने के लिए, निम्नलिखित चरणों का पालन करें:</p>
              <div className="steps-container">
                {steps.map((step, index) => (
                  <div className="step-card" key={index} style={{ backgroundColor: step.bgColor }}>
                    <h3 style={{ color: step.textColor }}>{step.title}</h3>
                    <p>{step.description}</p>
                  </div>
                ))}
              </div>
            </section>

            <section className='kusum-undestanding initiative' id='initiative'>
              <div className="understanding-content">
                <h2>How Can You Invest In PM-KUSUM Yojana</h2>
                <p>Investing in the PM-KUSUM Yojana is a wise approach to take advantage of government incentives while simultaneously supporting sustainable agriculture. The Pradhan Mantri Kisan Urja Suraksha Evam Utthaan Mahabhiyan, or PM-KUSUM Solar Yojana, intends to give farmers solar-powered irrigation and other agricultural needs solutions. </p>
              </div>
              <img src={kusum11} alt="Understanding PM-KUSUM" />
            </section>

            <section id='invest'>
              <h2>Steps To Invest in PM-KUSUM Yojana</h2>
              <div className="objectives invest">
                <ul>
                  <li>
                    <div className="objective-img invest-img">
                      <img src={kusum12} alt="" />
                    </div>
                    <div>
                      <h3>Research And Understand The Scheme</h3>
                      <p>Start by becoming acquainted with the specifics of PM Kisan Kusum Yojana. It is essential to comprehend its constituent parts, including the Solar Pump Kusum Yojana and the Kusum Solar Yojana, to make informed decisions.</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img invest-img">
                      <img src={kusum13} alt="" />
                    </div>
                    <div>
                      <h3>Verify Your Eligibility</h3>
                      <p>Make sure you fulfill the requirements to receive the Kusum Yojana subsidy. इस योजना का मुख्य लक्ष्य किसान और सहकारी समितियाँ हैं, और योजना के विशेष घटक के आधार पर पात्रता की शर्तें अलग-अलग हो सकती हैं!</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img invest-img">
                      <img src={kusum14} alt="" />
                    </div>
                    <div>
                      <h3>Application Process</h3>
                      <p>To apply for the Kusum Solar Panel Yojana, go to the official website or get in touch with your local agricultural offices. आवेदन प्रक्रिया में आमतौर पर फॉर्म भरना और अपनी पात्रता और रुचि साबित करने के लिए आवश्यक दस्तावेज़ प्रस्तुत करना शामिल होता है।</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img invest-img">
                      <img src={kusum15} alt="" />
                    </div>
                    <div>
                      <h3>Financial Planning</h3>
                      <p>समय के साथ, सौर समाधान में किए गए निवेश लागत-कुशल उपाय साबित हो सकते हैं। To make the investment more accessible, look into the different financing options available, such as government subsidies through the Kusum Yojana subsidy plan.</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img invest-img">
                      <img src={kusum16} alt="" />
                    </div>
                    <div>
                      <h3>Installation and Implementation</h3>
                      <p>मंजूरी मिलने के बाद, स्वीकृत विक्रेताओं के साथ मिलकर सोलर पैनल स्थापित करें। ग्रिड से जुड़े सोलर पैनल हों या एकल सोलर पंप, सही ढंग से स्थापना करना अधिकतम लाभ प्राप्त करने के लिए महत्वपूर्ण है।</p>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </main>
        </div>
      </section>

      <section className="info-cta-section">
        <div className="info-cta-section-content c-container">
          <div className="info-cta-content">
            <div className="info-cta-image">
              <img src={kusum18} alt="Sustainable Agriculture" />
            </div>
            <div className="info-cta-text">
              <p>
              A big step toward sustainable agriculture and farmer energy independence is the PM Kisan KUSUM yojana. लक्ष्यों, लाभों, और पंजीकरण प्रक्रिया की समझ के माध्यम से, किसान इस प्रयास को अपने जीवनस्तर को सुधारने और पर्यावरण पर सकारात्मक प्रभाव डालने के लिए बेहतर ढंग से उपयोग कर सकते हैं।
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-contact-form">
        <div className="inner-contact-form-content c-container">
          <div className="inner-contact-form-heading">
            Contact Us
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="vertical-form">
            <div className="form-container">
              <div className="form-group">
                <input type="text" className='form-control' placeholder='Your name' {...register('name', { required: 'Required' })} />
                {errors.name && <label className="error-message">{errors.name.message}</label>}
              </div>

              <div className="form-group">
                <input type="email" className='form-control' placeholder='Email' {...register('email', { required: 'Required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email' } })} />
                {errors.email && <label className="error-message">{errors.email.message}</label>}
              </div>

              <div className="form-group">
                <input type="tel" className='form-control' placeholder='Phone number' {...register('phone', {
                  required: 'Required', pattern: {
                    value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                    message: 'Invalid phone number',
                  }
                })} />
                {errors.phone && <label className="error-message">{errors.phone.message}</label>}
              </div>

              <div className="form-group">
                <Controller
                  name="service_name"
                  defaultValue={""}
                  control={control}
                  rules={{ required: 'Please select a service' }}
                  render={({ field }) => (
                    <select {...field} className='form-control' onChange={(e) => setValue('service_name', e.target.value)}>
                      <option value="" disabled>Select a service</option>
                      <option value="Solar Panels">Solar Panels</option>
                      <option value="Solar Panels">Inverters</option>
                      <option value="Solar Panels">Cable Wires</option>
                      <option value="Solar Panels">PPA</option>
                    </select>
                  )}
                />
                {errors.service_name && <label className="error-message">{errors.service_name.message}</label>}

              </div>

              <div className="form-group">
                <Controller
                  name="state"
                  defaultValue={""}
                  control={control}
                  rules={{ required: 'Please select a state' }}
                  render={({ field }) => (
                    <select
                      className='form-control'
                      {...field} onChange={(e) => setValue('state', e.target.value)}>
                      <option value="" disabled>State</option>
                      {states?.map((el) => {
                        return (
                          <option key={el?.id} value={el?.id}>
                            {el?.state}
                          </option>
                        )
                      })}
                    </select>
                  )}
                />
                {errors.state && <label className="error-message">{errors.state.message}</label>}

              </div>

              <div className="form-group">
                <Controller
                  name="city"
                  defaultValue={""}
                  control={control}
                  rules={{ required: 'Please select a city' }}
                  render={({ field }) => (
                    <select
                      className='form-control'
                      {...field} onChange={(e) => setValue('city', e.target.value)}>
                      <option value="" disabled>City</option>
                      {city?.map((el) => {
                        return (
                          <option key={el?.id} value={el?.id}>
                            {el?.city_name}
                          </option>
                        )
                      })}
                    </select>
                  )}
                />
                {errors.city && <label className="error-message">{errors.city.message}</label>}

              </div>

              <div className="form-group">
                <Controller
                  name="type"
                  defaultValue={""}
                  control={control}
                  rules={{
                    required: 'Please select a type'
                  }}

                  render={({ field }) => (
                    <select {...field} className='form-control' onChange={(e) => setValue('type', e.target.value)}>
                      <option value="" disabled>Select a type</option>
                      <option value="dealer">Dealer</option>
                      <option value="retailer">Retailer</option>
                      <option value="distributor">Distributor</option>
                      <option value="epc player">EPC Player</option>
                      <option value="consumer">Consumer</option>
                    </select>
                  )}
                />
                {errors.type && <div className="error-message">{errors.type.message}</div>}
              </div>

              <div className="form-group" >
                <textarea placeholder='Your message' className='form-control' {...register('message', { required: 'Required' })} />
                {errors.message && <label className="error-message">{errors.message.message}</label>}
              </div>

              <input type="hidden" {...register('enquiry_type')} />

              <div className="form-group">
                <ReCAPTCHA
                  sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                  onChange={handleVerificationSuccess}
                />
                {captchaError && <div className="error-message">{captchaError}</div>}
              </div>

            </div>
            <div className="form-footer">
              <button className='common-btn enquiry-btn' type="submit">Submit</button>
            </div>
          </form>
        </div>
      </section>

    </>
  )
}

export default KusumYojana