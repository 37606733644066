import React, { useState, useEffect, Suspense, useContext } from 'react';
import "./BecamePartner.css";
import BigTitle2 from '../../components/BigTitle/BigTitle2';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';

import partnerSvg from "../../assets/become-partner-vector.svg"
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import { LuUser2 } from "react-icons/lu";
import { MdOutlineEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { RiMapPin4Line } from "react-icons/ri";
import { LiaCitySolid } from "react-icons/lia";
import { Helmet } from 'react-helmet';

const ReCAPTCHA = React.lazy(() => import('react-google-recaptcha'));


const BecamePartner = () => {
    const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            enquiry_type: '0'
        }
    });
    const [showReCAPTCHA, setShowReCAPTCHA] = useState(false);
    const [captchaError, setCaptchaError] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState("");
    const navigate = useNavigate()
    const { states, city, setSelectedState } = useContext(AppContext);

    const handleFormInteraction = () => {
        if (!showReCAPTCHA) {
            setShowReCAPTCHA(true);
        }
    };

    const handleVerificationSuccess = (token) => {
        setCaptchaToken(token);
    };

    const selectedState = watch('state')

    useEffect(() => {
        setSelectedState(selectedState);
    }, [selectedState, setSelectedState]);

    const handleBrandChange = (e) => {
        const value = e.target.value;
        setSelectedBrand(value);
        setValue('service_name', value);
        if (value !== 'Axitec') {
            setValue('state', 'Rajasthan');
        }
    };


    const onSubmit = async (data) => {
        data.captchaToken = captchaToken;
        const formBody = new URLSearchParams(data).toString();
        try {
            const response = await fetch('https://kesrinandan.com/admin/api/quote-enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody,
            });

            const responseData = await response.json();

            if (response.ok) {
                reset()
                navigate("/thank-you")
            } else {
                if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
                    setCaptchaError(responseData.msg);
                } else {
                    console.error('Failed to submit the form:', responseData.error);
                }
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };


    return (
        <>
            <Helmet>
                <title>Partner with Kesrinandan | Solar Power Dealership Opportunities</title>
                <meta
                    name="description"
                    content=" Join Kesrinandan as a dealer and explore solar power dealership opportunities. Become one of our esteemed solar panel dealers &unlock your potential in the renewable energy industry."
                />
                <link rel="canonical" href="https://kesrinandan.com/become-partner" />
                {/* Facebook Meta Tags */}
                <meta property="og:url" content="https://kesrinandan.com/become-partner" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Partner with Kesrinandan | Solar Power Dealership Opportunities" />
                <meta property="og:description" content="Join Kesrinandan as a dealer and explore solar power dealership opportunities. Become one of our esteemed solar panel dealers &unlock your potential in the renewable energy industry." />
                <meta property="og:image" content="https://kesrinandan.com/static/media/became-partner-banner.fbe40c6ea174dc0ddec7.webp" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content="https://kesrinandan.com/become-partner" />
                <meta name="twitter:title" content="Partner with Kesrinandan | Solar Power Dealership Opportunities" />
                <meta name="twitter:description" content="Join Kesrinandan as a dealer and explore solar power dealership opportunities. Become one of our esteemed solar panel dealers &unlock your potential in the renewable energy industry." />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/became-partner-banner.fbe40c6ea174dc0ddec7.webp" />
            </Helmet>

            <section className="about-banner become-partner-banner">
                <div className="custom-text custom-text-about">
                    {/* <p className='custom-title'>Our Services<span className='color-dot'>.</span></p> */}
                    <div className="breadcrumb-hide" style={{ visibility: "hidden" }}>
                        <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Sectors', url: '/sectors' }]} />
                    </div>
                </div>
            </section>
            <section className="banner-heading-section banner-heading-section2">
                <div className="c-container">
                    <BigTitle2 heading="About Kesrinandan" />
                    <p className='become-partner-heading-desc'>Kesrinandan Ventures LLP business, established in Rajasthan, has been distributing solar products for project development, rooftop solar solutions, and solar water pumps since 2011. We are well known for offering premium, cost-effective sustainable energy solutions that leverage cutting-edge technology. Starting in Kota and other cities in Rajasthan, we have now expanded nationwide. We are official distributors for renowned brands like Leoni, Axitec, Panasonic, REC, and Livguard.</p>
                </div>
            </section>

            <div className="c-container">
                <section className="become-partner">
                    <div className="partner-content">
                        <div className="partner-info">
                            <h2>Solar Panel Dealership </h2>
                            <p>As part of our consistently growing strategy, we are offering solar dealership opportunities. Through this approach we will bring our products closer to every person, creating highly profitable chances for our business partners. We backing our dealers through the marketing resources, technical training, and helpdesk, which are exclusively dedicated to them. A partnership with Kesrinandan means an exclusive subset, low price, and firm brand name; it can guarantee the competitive power of business.</p>
                        </div>
                        <img src={partnerSvg} alt="Descriptive Alt Text" className="partner-image" />
                        <div className="partner-info">
                            <h2>Find the solar panel dealer near you</h2>
                            <p>Join Kesrinandan Ventures today and start your own business with our solar panel dealership program. This is the best opportunity to expand your business in the rapidly growing solar industry in various cities. Sign up today and profit from the highly demanding solar energy solutions.</p>
                        </div>
                    </div>
                    <div className="partner-form">
                        <form onFocus={handleFormInteraction} onClick={handleFormInteraction} onSubmit={handleSubmit(onSubmit)} className="vertical-form">
                            <div className="form-container form-container2">

                                <div className="form-group">
                                    <div className="icon">
                                        <LuUser2 />
                                    </div>
                                    <input type="text" className='form-control' placeholder='Your name' {...register('name', { required: 'Required' })} />
                                    {errors.name && <div className="error-message">{errors.name.message}</div>}
                                </div>

                                <div className="form-group">
                                    <div className="icon">
                                        <MdOutlineEmail />
                                    </div>
                                    <input type="email" className='form-control' placeholder='Email' {...register('email', { required: 'Required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email' } })} />
                                    {errors.email && <div className="error-message">{errors.email.message}</div>}
                                </div>

                                <div className="form-group">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <input type="tel" className='form-control' placeholder='Phone number' {...register('phone', {
                                        required: 'Required', pattern: {
                                            value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                                            message: 'Invalid phone number',
                                        }
                                    })} />
                                    {errors.phone && <div className="error-message">{errors.phone.message}</div>}
                                </div>

                                <div className="form-group">
                                    <div className="icon">
                                        <VscTypeHierarchySub />
                                    </div>
                                    <Controller
                                        name="service_name"
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: 'Please select a Brand' }}
                                        render={({ field }) => (
                                            <select {...field} className='form-control' onChange={handleBrandChange}>
                                                <option value="" disabled>Select a brand</option>
                                                <option value="Axitec">Axitec</option>
                                                <option value="Rec">Rec</option>
                                                <option value="Livguard">Livguard</option>
                                                <option value="Panasonic">Panasonic</option>
                                                <option value="Eastman">Eastman</option>
                                            </select>
                                        )}
                                    />
                                    {errors.service_name && <div className="error-message">{errors.service_name.message}</div>}
                                </div>

                                <div className="form-group">
                                    <div className="icon">
                                        <RiMapPin4Line />
                                    </div>
                                    <Controller
                                        name="state"
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: 'Please select a state' }}
                                        render={({ field }) => (
                                            <select {...field} className='form-control' onChange={(e) => setValue('state', e.target.value)}>
                                                <option value="">Select a state</option>
                                                {selectedBrand === 'Axitec'
                                                    ? states.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {el.state}
                                                        </option>
                                                    ))
                                                    : states
                                                        .filter((el) => el.state === 'Rajasthan')
                                                        .map((el) => (
                                                            <option key={el.id} value={el.id}>
                                                                {el.state}
                                                            </option>
                                                        ))}
                                            </select>
                                        )}
                                    />
                                    {errors.state && <div className="error-message">{errors.state.message}</div>}
                                </div>

                                <div className="form-group">
                                    <div className="icon">
                                        <LiaCitySolid />
                                    </div>
                                    <Controller
                                        name="city"
                                        defaultValue={""}
                                        control={control}
                                        rules={{
                                            required: 'Please select a city'
                                        }}

                                        render={({ field }) => (
                                            <select {...field} className='form-control' onChange={(e) => setValue('city', e.target.value)}>
                                                <option value="" disabled>Select a city</option>
                                                {city?.map((el) => {
                                                    return (
                                                        <option key={el?.id} value={el?.id}>
                                                            {el?.city_name}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        )}
                                    />
                                    {errors.city && <div className="error-message">{errors.city.message}</div>}
                                </div>

                                <div className="form-group text-area-group" >
                                    <textarea placeholder='Your message' className='form-control form-control-textarea' {...register('message', { required: 'Required' })} />
                                    {errors.message && <div className="error-message">{errors.message.message}</div>}
                                </div>

                                <input type="hidden" {...register('enquiry_type')} />

                                <div className="form-group">
                                    {showReCAPTCHA && (
                                        <Suspense fallback={<div>Loading reCAPTCHA...</div>}>
                                            <ReCAPTCHA
                                                sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                                                onChange={handleVerificationSuccess}
                                            />
                                        </Suspense>
                                    )}
                                    {captchaError && <div className="error-message">{captchaError}</div>}
                                </div>

                            </div>
                            <div className="form-footer">
                                <button className='common-btn enquiry-btn' type="submit">Submit Request</button>
                            </div>
                        </form>
                    </div>


                    <div className="contactor-container">
                        <h2>Why Should You Choose Kesrinandan for the Solar Dealership Program</h2>
                        <div className="row">
                            <div className="contractor-item">
                                <h5 className="dark-orange-links">Business Promotion on Google</h5>
                                <p>We will help you in promoting your business on Google to increase visibility and attract more customers.</p>
                            </div>
                            <div className="contractor-item">
                                <h5 className="dark-orange-links">Marketing Support</h5>
                                <p> Get help with advertising your products and creating new dealership networks.
                                </p>
                            </div>
                            <div className="contractor-item">
                                <h5 className="dark-orange-links">Local Distribution</h5>
                                <p>All dealers in a particular location will source materials from nearby distributors, ensuring a proper supply chain.</p>
                            </div>
                            <div className="contractor-item">
                                <h5 className="dark-orange-links">Technical Assistance</h5>
                                <p>Kesrinandan will provide all necessary technical help and support to ensure your business runs smoothly.</p>
                            </div>
                        </div>
                        <p style={{ textAlign: "center" }}>By partnering with us, you’ll have the backing you need to successfully grow your solar business.</p>
                    </div>

                </section>

            </div>

        </>
    )
}

export default BecamePartner