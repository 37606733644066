import React from 'react';
import "./OurBrandsSection.css";
import Slider from "react-slick";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import img from '../../assets/brands/axitec.webp';
import img1 from '../../assets/brands/panasonic.webp';
import img2 from '../../assets/brands/livguard.webp';
import img3 from '../../assets/brands/rec.webp';
import img4 from "../../assets/brands/Group 113.svg";
import img5 from "../../assets/brands/Group 114.svg";
import img6 from "../../assets/brands/Group 115.svg";
import img7 from "../../assets/brands/Group 116.svg";

const OurBrandsSection = () => {
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2500,
        pauseOnHover: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <div className="c-container">
            <div className='our-brands-section'>
                <div className="brands">
                    <div className="partner-logos logo-show">
                        <div className="brands-item">
                            <LazyLoadImage
                                src={img}
                                alt=""
                                className='brands-logo1'
                            />
                        </div>
                        <div className="brands-item">
                            <LazyLoadImage
                                src={img3}
                                alt=""
                                className='brands-logo4'
                            />
                        </div>
                        <div className="brands-item">
                            <LazyLoadImage
                                src={img1}
                                alt=""
                                className='brands-logo2'
                            />
                        </div>
                        <div className="brands-item">
                            <LazyLoadImage
                                src={img2}
                                alt=""
                                className='brands-logo3'
                            />
                        </div>
                    </div>
                    <div className='logo-hide'>
                        <Slider {...settings} >
                            <div>
                                <div className="brands-item">
                                    <LazyLoadImage
                                        src={img}
                                        alt=""
                                        className='brands-logo1 lazy2'
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="brands-item">
                                    <LazyLoadImage
                                        src={img2}
                                        alt=""
                                        className='brands-logo3 lazy2'
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="brands-item">
                                    <LazyLoadImage
                                        src={img1}
                                        alt=""
                                        className='brands-logo2 lazy2'
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="brands-item">
                                    <LazyLoadImage
                                        src={img3}
                                        alt=""
                                        className='brands-logo4 lazy2'
                                    />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className="brands-work">
                    <div className="brands-work-item">
                        <div className="brands-work-item-logo">
                            <LazyLoadImage
                                src={img4}
                                alt=""
                                className='lazy2'
                            />
                        </div>
                        <div className="brands-work-item-content">
                            <h4>₹ 30+ Cr</h4>
                            <p>Customers saved on electricity by installing solar plants.</p>
                        </div>
                    </div>
                    <div className="brands-work-item">
                        <div className="brands-work-item-logo">
                            <LazyLoadImage
                                src={img5}
                                alt=""
                                className='lazy2'
                            />
                        </div>
                        <div className="brands-work-item-content">
                            <h4>150+ Users</h4>
                            <p>Happy customers we are working with.</p>
                        </div>
                    </div>
                    <div className="brands-work-item">
                        <div className="brands-work-item-logo">
                            <LazyLoadImage
                                src={img6}
                                alt=""
                                className='lazy2'
                            />
                        </div>
                        <div className="brands-work-item-content">
                            <h4>11.5 MW</h4>
                            <p>Solar plants successfully installed across Rajasthan.</p>
                        </div>
                    </div>
                    <div className="brands-work-item">
                        <div className="brands-work-item-logo">
                            <LazyLoadImage
                                src={img7}
                                alt=""
                                className='lazy2'
                            />
                        </div>
                        <div className="brands-work-item-content">
                            <h4>13+ Years Experience</h4>
                            <p>We have been actively working in the same industry for the past 13 years.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurBrandsSection;
