import React, { useState, useEffect, useContext } from 'react';
import "./Header.css";

// Images
import Phone from "../../assets/home/Group-3.svg";
import Email from "../../assets/home/Group-4.svg";
import Fb from "../../assets/home/Group.svg";
import In from "../../assets/home/Group-1.svg";
// import x from "../../assets/footer/x.png";
import Insta from "../../assets/home/Group-2.svg";
import Logo from "../../assets/home/kesrinandan.svg";

import EnquiryModal from "../../components/EnquiryModal/EnquiryModal";
import { AppContext } from '../../AppContext';

import { FaBarsStaggered } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import SearchModal from '../SearchModal/SearchModal';

const Header = () => {
    const [stickyClass, setStickyClass] = useState("");
    const [navMenu, setNavMenu] = useState("")
    const [isModalOpen, setModalOpen] = useState(false);
    const { menu, loading, error } = useContext(AppContext);

    const scrollTo = () => {
        window.scrollTo(0, 0);
    }

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        const stickNavbar = () => {
            if (window.scrollY > 80) {
                setStickyClass("sticky-nav")
            } else {
                setStickyClass("")
            }
        };

        window.addEventListener('scroll', stickNavbar);

        return () => {
            window.removeEventListener('scroll', stickNavbar);
        };
    }, []);


    const isLandingPage = () => {
        const landingPages = ['/landingpage/axitec', '/landingpage/livguard'];
        return landingPages.includes(window.location.pathname);
    };


    return (
        <>
            <EnquiryModal
                isOpen={isModalOpen} onClose={closeModal}
            />
            <header className={`${navMenu}`}>
                <div className="header-top">
                    <div className="header-top-container">
                        <div className='header-contact'>
                            <Link to={"mailto:info@kesrinandan.com"} className='header-contact-item'>
                                <img src={Email} alt="email" />
                                <span>info@kesrinandan.com</span>
                            </Link>
                            <Link to={"tel:+91-9828118888"} className='header-contact-item'>
                                <img src={Phone} alt="phone" />
                                <span>+91-9828118888</span>
                            </Link>
                        </div>
                        <div className='header-social'>
                            <Link target='_blank' to={"https://www.facebook.com/KesrinandanVentures.solar/"}>
                                <img src={Fb} alt="facebook" className='header-social-icon' />
                            </Link>

                            <Link target='_blank' to={"https://www.linkedin.com/company/kesrinandan-ventures/"}>
                                <img src={In} alt="LinkedIn" className='header-social-icon' />
                            </Link>

                            <Link target='_blank' to={"https://www.instagram.com/kesrinandan_ventures/"}>
                                <img src={Insta} alt="Instagram" className='header-social-icon' />
                            </Link>
                        </div>
                    </div>
                </div>

                <nav className={`${stickyClass}`}>
                    <div className="nav-logo" onClick={() => {
                        setNavMenu("")
                        scrollTo()
                    }}>
                        <Link to={"/"}>
                            <img src={Logo} alt="logo" />
                        </Link>
                    </div>
                    <ul className="nav-items">
                        {!isLandingPage() && (
                            <>
                                <li onClick={() => {
                                    setNavMenu("")
                                    scrollTo()
                                }}>
                                    <Link to={"/our-company"}>
                                        About Us
                                    </Link>
                                </li>
                                <li className='dropdown-item'><span onClick={() => {
                                    setNavMenu("")
                                    scrollTo()
                                }}>Products</span>
                                    <ul className='sub-menu sub-menu-2'>
                                        {menu.category?.map((el) => {
                                            return (
                                                <Link to={`/product/${el?.slug}`} key={el?.id} onClick={() => {
                                                    setNavMenu("")
                                                    scrollTo()
                                                }}>
                                                    <li>{el?.slug.replace(/-/g, ' ')}</li>
                                                </Link>
                                            )
                                        })}
                                    </ul>

                                </li>
                                <li className='dropdown-item'><span onClick={() => {
                                    setNavMenu("")
                                    scrollTo()
                                }}><Link to={`/partners`}>Partners</Link></span>
                                    <ul className='sub-menu sub-menu-2'>
                                        <Link to={`/brand-detail/axitec`} onClick={() => {
                                            setNavMenu("")
                                            scrollTo()
                                        }}>
                                            <li>Axitec</li>
                                        </Link>
                                        <Link onClick={() => {
                                            setNavMenu("")
                                            scrollTo()
                                        }} to={`/brand-detail/rec`}>
                                            <li >Rec</li>
                                        </Link>
                                        <Link onClick={() => {
                                            setNavMenu("")
                                            scrollTo()
                                        }} to={`/brand-detail/livguard`}>
                                            <li>Livguard</li>
                                        </Link>
                                        <Link onClick={() => {
                                            setNavMenu("")
                                            scrollTo()
                                        }} to={`/brand-detail/panasonic`}>
                                            <li >Panasonic</li>
                                        </Link>
                                        <Link onClick={() => {
                                            setNavMenu("")
                                            scrollTo()
                                        }} to={`/brand-detail/eastman`}>
                                            <li >Eastman</li>
                                        </Link>
                                    </ul>

                                </li>
                                <li onClick={() => {
                                    setNavMenu("")
                                    scrollTo()
                                }}>
                                    <Link to={"/sectors"}>Sectors</Link>
                                </li>

                                <li className='dropdown-item'><span onClick={() => {
                                    setNavMenu("")
                                    scrollTo()
                                }}>Schemes</span>
                                    <ul className='sub-menu sub-menu-2'>
                                        <Link to={`/ppa`} onClick={() => {
                                            setNavMenu("")
                                            scrollTo()
                                        }}>
                                            <li>PPA</li>
                                        </Link>
                                        <Link to={`/kusum-yojana`} onClick={() => {
                                            setNavMenu("")
                                            scrollTo()
                                        }}>
                                            <li>Kusum Yojana</li>
                                        </Link>
                                    </ul>

                                </li>

                                <li onClick={() => {
                                    setNavMenu("")
                                    scrollTo()
                                }}>
                                    <Link to={"/contact"}>Contact</Link>
                                </li>
                            </>
                        )}

                        <Link onClick={() => {
                            setNavMenu("")
                            scrollTo()
                        }} to={"/become-partner"} variant="primary" className="common-btn nav-btn partner-btn">
                            Become A Partner
                        </Link>
                        <button variant="primary" onClick={openModal} className="common-btn nav-btn">
                            Enquiry
                        </button>
                        <SearchModal setNavMenu={setNavMenu} scrollTo={scrollTo}/>

                    </ul>
                    <div className="toggle-btn">
                        <FaBarsStaggered className={`open-btn`} onClick={() => setNavMenu("nav-menu")} />
                        <IoCloseSharp className={`close-btn`} onClick={() => setNavMenu("")} size={30} />
                    </div>

                </nav>
            </header>
        </>
    )
}

export default Header