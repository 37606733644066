import React, { useRef }  from 'react';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import { Helmet } from 'react-helmet';
import './Disclaimer.css';
import pdf from "../../assets/Disclaimer for Kesrinandan Ventures LLP.pdf";
import { usePdf } from '@mikecousins/react-pdf';
import BigTitle2 from '../../components/BigTitle/BigTitle2';

const Disclaimer = () => {
    const canvasRef = useRef(null);

    const { pdfDocument } = usePdf({
        file: pdf,
        canvasRef,
    });


    return (
        <>
            <Helmet>
                <title>Get Your Path to Success With Kesrinandan Ventures</title>
                <meta
                    name="description"
                    content="Discover exciting career prospects and hiring opportunities at Kesrinandan. Explore our current openings & start your journey towards a rewarding career today."
                />

                <link rel="canonical" href="https://kesrinandan.com/disclaimer" />

                {/* Facebook Meta Tags */}
                {/* <meta property="og:url" content="https://kesrinandan.com/career" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Get Your Path to Success With Kesrinandan Ventures" />
                <meta property="og:description" content="Discover exciting career prospects and hiring opportunities at Kesrinandan. Explore our current openings & start your journey towards a rewarding career today." />
                <meta property="og:image" content="https://kesrinandan.com/static/media/career-banner.c30f7b24d55197c4412a.png" /> */}

                {/* Twitter Meta Tags */}
                {/* <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content="https://kesrinandan.com/career" />
                <meta name="twitter:title" content="Get Your Path to Success With Kesrinandan Ventures" />
                <meta name="twitter:description" content="Discover exciting career prospects and hiring opportunities at Kesrinandan. Explore our current openings & start your journey towards a rewarding career today." />
                <meta name="twitter:image" content="https://kesrinandan.com/static/media/career-banner.c30f7b24d55197c4412a.png" /> */}

            </Helmet>

            <section className="about-banner career-banner">
                <div className="custom-text custom-text-about">
                    <div className="breadcrumb-hide" style={{ visibility: "hidden" }}>
                        <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Career', url: '/career' }]} />
                    </div>
                </div>
            </section>

            <section className="banner-heading-section banner-heading-section2">
                <div className="c-container">
                    <BigTitle2 heading="Disclaimer" />
                </div>
            </section>

            <div className="disclaimer-section">
                <div className="c-container">
                    <div className='canvas-container'>
                        {!pdfDocument && <span>Loading...</span>}
                        <canvas ref={canvasRef} />
                    </div>
                </div>
            </div>

        </>

    )
}

export default Disclaimer